import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {BehaviorSubject, from} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';
import { ConfigService } from 'src/app/services/config/config.service';

@Injectable({
    providedIn: 'root'
})

export class AouthService {
    private user = new BehaviorSubject(null);
  
    constructor(private http: HttpClient, private platform: Platform, private config: ConfigService) {
        this.platform.ready().then(() => {
        Storage.get({key: this.config.aouthStoragekey}).then(data => {
            if (data.value) {
                try {
                    const value = JSON.parse(data.value);
                    this.user.next(value);
                }
                catch(err) {
                    this.user.next(null);
                }
            }
            else{
                this.user.next(null);
            }
        }) });
    }

    setAouth(){

    }
 
    signIn(username, password) {
        return this.http.post(`${this.config.apiUrl}/jwt-auth/v1/token`, { username, password }).pipe(
        switchMap(data => {
            if(data && Object(data).token){
                return from(Storage.set({key:this.config.aouthStoragekey, value: JSON.stringify(data)}));
            }
            else{
                return from(Storage.remove({key:this.config.aouthStoragekey}).then(() => {
                    this.user.next(null);
                }));
            }
        }) );
    }

    verifyToken() {
        return this.http.post(`${this.config.apiUrl}/jwt-auth/v1/token/validate`, {});
    }
 
    signUp(username, email, password) {
        return this.http.post(`${this.config.apiUrl}/wp/v2/users/register`, { username, email, password });
    }
 
    resetPassword(usernameOrEmail) {
        return this.http.post(`${this.config.apiUrl}/wp/v2/users/lostpassword`, { user_login: usernameOrEmail });
    }
    
    getPrivatePosts() {
        return this.http.get<any[]>(`${this.config.apiUrl}/wp/v2/posts?_embed&status=private`).pipe(
            map(data => {
                return data;
            })
        );
    }

  
    getPosts() {
        return this.http.get<any[]>(`${this.config.apiUrl}/wp/v2/posts`).pipe(
            map(data => {
                return data;
            })
        );
    }

    getCurrentUser() {
        return this.user.asObservable();
    }
    
    getUserValue() {
        return this.user.getValue();
    }
    
    logout() {    
        Storage.remove({key: this.config.aouthStoragekey}).then(() => {
        this.user.next(null);
        });
    }
}
